@font-face {
  font-family: 'Monument Extended';
  src: url('./MonumentExtended/MonumentExtended-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Monument Extended';
  src: url('./MonumentExtended/MonumentExtended-Ultrabold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Surt';
  src: url('./Surt/Surt-Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Surt';
  src: url('./Surt/Surt-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Surt';
  src: url('./Surt/Surt-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Surt';
  src: url('./Surt/Surt-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Surt';
  src: url('./Surt/Surt-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NeoSans';
  src: url('./NeoSansProCyr-Black.woff2');
  font-weight: 500;
  font-style: normal;
}
